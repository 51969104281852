<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="deleteManualStep"/>
        <div class="d-flex justify-start mb-3">
            <v-btn color="primary" @click="redirectBack">
                <v-icon>
                    mdi-arrow-left-bold
                </v-icon>
            </v-btn>
        </div>
        <v-skeleton-loader
        ref="skeleton" type="table" v-if="this.api.isLoading ">

        </v-skeleton-loader>
        <v-data-table
        :headers="dataHeaders" 
        :items=data v-if="!api.isLoading && data!=null">
            <template v-slot:top>
                <v-toolbar color="secondary white--text" class="text-h5">
                    Steps-by-steps
                    <v-spacer>
                    </v-spacer>
                    <v-btn color="primary" @click="redirectToManualStepsCreate">
                        New
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:item.created_at="{item}">
                {{ item.created_at.split("T")[0] }}
            </template>
            <template v-slot:item.index="{item}">
                {{ data.indexOf(item)+1 }}
            </template> 
            <template v-slot:item.attachment_name="{item}">
                <a :href="item.attachment_url" target="_black" v-if="item.attachment_name!=null">
                    {{ item.attachment_name }}
                </a>
            </template>
            <template v-slot:item.action="{item}">
                <v-btn color="primary" @click="redirectToManualStepsUpdate(item.step_id)" plain>
                    Edit
                </v-btn>
                <v-btn color="red" @click="beforeDelete(item.step_id)" plain>
                    Delete
                </v-btn>
            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
export default {
    components:{
    AConfirmation
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
    data:null,
    deletedId:null,
    isPending:false,
    dataHeaders:[
        {
            text:'#',
            value:'index',
        },
        {
            text:'Title',
            value:'title',
        },
        {
            text:'Step',
            value:'step',
        },
        {
            text:'Image',
            value:'attachment_name'
        },
        {
            text:'Order',
            value:'order'
        },
        {
            text:'Date ',
            value:'created_at',
        },
        {
            text:'Action',
            value:'action',
        },
    ],
    api:{
        isSucessful:false,
        isLoading :false,
        isError:false,
        error:null,
        url:null,
    }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getListofManualStep"){
                this.data = resp.data;
            }
            if(resp.class=="deleteManualStep"){
                location.reload();
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
    this.fetch();
    },
    methods: {
        fetch(){
            let fetchManualStepsApi = this.fetchManualSteps();
            this.$api.fetch(fetchManualStepsApi);
        },
        fetchManualSteps(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/manual/steps/"+this.$router.history.current.params.id;
            return tempApi;
        },
        redirectToManualStepsCreate(){
            this.$router.push({name:'PageManualStepCreate',params:{'id':this.$router.history.current.params.id}});
        },
        redirectToManualStepsUpdate(id){
            this.$router.push({name:'PageManualStepUpdate',params:{'id':id}})
        },
        redirectBack(){
            this.$router.go(-1);
        },
        beforeDelete(id){
            this.isPending = true;
            this.deletedId = id;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        deleteManualStep(){
            this.isPending = false;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "DELETE";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/manual/step/"+this.deletedId;
            this.$api.fetch(tempApi);
        }
    }
}
</script>