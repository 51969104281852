var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('AConfirmation',{attrs:{"isShow":_vm.isPending},on:{"cancel":_vm.cancelSubmit,"confirm":_vm.deleteManualStep}}),_c('div',{staticClass:"d-flex justify-start mb-3"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.redirectBack}},[_c('v-icon',[_vm._v(" mdi-arrow-left-bold ")])],1)],1),(this.api.isLoading )?_c('v-skeleton-loader',{ref:"skeleton",attrs:{"type":"table"}}):_vm._e(),(!_vm.api.isLoading && _vm.data!=null)?_c('v-data-table',{attrs:{"headers":_vm.dataHeaders,"items":_vm.data},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"text-h5",attrs:{"color":"secondary white--text"}},[_vm._v(" Steps-by-steps "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.redirectToManualStepsCreate}},[_vm._v(" New ")])],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at.split("T")[0])+" ")]}},{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.data.indexOf(item)+1)+" ")]}},{key:"item.attachment_name",fn:function(ref){
var item = ref.item;
return [(item.attachment_name!=null)?_c('a',{attrs:{"href":item.attachment_url,"target":"_black"}},[_vm._v(" "+_vm._s(item.attachment_name)+" ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":function($event){return _vm.redirectToManualStepsUpdate(item.step_id)}}},[_vm._v(" Edit ")]),_c('v-btn',{attrs:{"color":"red","plain":""},on:{"click":function($event){return _vm.beforeDelete(item.step_id)}}},[_vm._v(" Delete ")])]}}],null,false,1417986295)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }